<template>

    <div class="p-1" v-cloak>
        <nav class="h-15vh sticky top-0 bg-gray-900">
            <div class="max-w-7xl mx-auto px-2 pt-2">
                <div class="relative">
                    <div class="inset-y-0 left-0 flex justify-between gap-3 items-center">
                        <router-link :to="{name: 'mobilepos.direct'}" class="flex justify-start gap-5">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M15 19l-7-7 7-7" />
                            </svg>
                            <h1 class="text-lg font-bold text-white">{{$t('translations.views.mobilepos.pages.receipt.go-back')}}</h1>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="flex justify-between px-3 mt-5">
                <h2 class="text-lg font-bold text-white">{{$t('translations.views.mobilepos.pages.receipt.your-order')}}</h2>
                <div class="flex items-center gap-4">
                    <div @click="toggleRefund()" class="refundIcon" :class="{'active' : transaction.total < 0 }">
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9998 8L6 14L12.9998 21" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div @click="transactionClear()">
                        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
        </nav>

        <div class="flex flex-col mobile-content-wrapper-checkout scrollbar-hide justify-between">
            <div v-if="transaction.lines.length > 0 || terminal.transactionscreen == 'empty'"
                 class="transaction-content overflow-auto flex-grow h-full relative">
                <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col h-full">
                    <!-- no flex allowed-->
                    <div class="flex flex-col-reverse">
                        <div class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start"
                             :class="{'selected' : line.selected}"
                             v-for="(line, index) in transaction.lines.slice().reverse()"
                             :key="index">
                            <div class="col-span-1 flex justify-end">
                                <div class="text-lg leading-5">{{ line.quantity }}</div>
                            </div>
                            <div class="col-span-2 flex justify-center ">
                                <a v-if="line.editable" @click="subQuantity(line)" class="leading-5 px-1 text-gray-300">
                                    <svg class="h-5 w-4" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                    </svg>
                                </a>
                            </div>
                            <div class="col-span-4 text-left">
                                <div class="text-lg leading-5 truncate"
                                     :class="{'ml-3 italic': (line.addon || !line.editable)}">{{ line.description }}
                                </div>
                                <div class="italic text-xs ml-3" v-if="line.notes">
                                    {{ line.notes }}
                                </div>
                                <div class="italic text-xs ml-3" v-if="line.cardnumber">
                                    {{ line.cardnumber }}
                                </div>
                                <div class="italic text-xs"
                                     v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                     v-bind:key="grocery_index">
                                    {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                </div>
                                <div class="italic text-xs ml-3" v-if="line.variant_label">
                                    {{ line.variant_label }}
                                </div>
                            </div>
                            <div class="col-span-1 flex justify-start">
                                <a v-if="line.editable" @click="addQuantity(line, index)"
                                   class="cursor-pointer text-lg leading-5 px-1 text-gray-300">
                                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                    </svg>
                                </a>
                            </div>

                            <div v-if="line.editable"
                                 class="text-lg col-span-3 flex items-center justify-end font-bold">
                                <div>
                                    <div>{{ vueNumberFormat(line.total) }}</div>
                                    <div class="text-xs text-gray-300" v-if="line.discount_amount > 0">
                                        (-{{ vueNumberFormat(line.discount_amount) }})
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-1">
                                <a class="flex justify-end items-center" v-if="line.editable"
                                   @click="lineSelect(line);lineSelectedDelete()">
                                    <svg class="text-gray-200 h-5 mt-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                              clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div v-if="transaction">
                    <div v-if="transaction.lines.length > 0" id="total"
                         class="bg-shadow p-1 pt-2 rounded-b-sm grid col-span-12">
                        <div class="flex justify-between font-bold text-lg">
                            <div></div>
                            <div class="flex justify-between">
                                <span class="mr-4">TOTAAL:</span>
                                <span><currency/>{{ vueNumberFormat(transaction.total) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-3 mt-3">
                    <div class="col-span-1">
                        <div @click="gotoTransaction()"
                             class="h-full button-sjefgreen-outline flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                            <span class="text-white">Alternatief</span>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div v-if="paymenttype" @click="gotoTransaction(paymenttype.id)"
                             class="h-full button-sjefgreen flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                            {{ vueNumberFormat(transaction.total) }} {{ paymenttype.attributes.name }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="grid grid-cols-2">
                <div class="col-span-1">
                    <div v-if="paymenttype" @click="gotoTransaction()" class="button-sjefgreen flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        Alternatief
                    </div>
                </div>
                <div class="col-span-1">
                    <div v-if="paymenttype" @click="gotoTransaction(paymenttype.id)" class="button-sjefgreen flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        {{ paymenttype.attributes.name }}
                    </div>
                </div>
            </div> -->
            <modal-confirm-refund :amount="transaction.total" ref="refund" @confirmed="setRefundAmount()" />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
// import axios from 'axios'

// import transaction from 
// components
// import LoadingIndicator from '../../../components/LoadingIndicator'
import ModalConfirmRefund from '../../../components/ModalConfirmRefund.vue'

// mixins
import PosHelper from '../../../mixins/pos.helper.js';
import transaction from '../../../store/transaction';

export default {
    name: 'mobilepos.direct',
    mixins: [PosHelper],
    emits: ['confirmed'],
    data () {
        return {
            initialized: false,
            refundActive: false,
        };
    },
    components: {
        ModalConfirmRefund,
    },
    mounted () {
        this.getPerferredPaymenttype();
    },

    methods: {
        ...mapActions({
            transactionClear: 'transaction/clear',
            addQuantity: 'transaction/addQuantity',
            subQuantity: 'transaction/subQuantity',
            lineSelect: 'transaction/lineSelect',
            // lineSelectAll: 'transaction/lineSelectAll',
            lineSelectedDelete: 'transaction/lineSelectedDelete',
            lineSelectedRetour: 'transaction/lineSelectedRetour',
            // lineSelectedDiscount: 'transaction/lineSelectedDiscount',
        }),

        setRefundAmount () {
            this.$refs.refund.close();

            this.toggleLinesRefund()
        },

        toggleLinesRefund () {
            this.$store.dispatch('transaction/selectAllLines');
            this.$store.dispatch('transaction/lineSelectedRetour');
            this.$store.dispatch('transaction/unselectAllLines');
        },

        toggleRefund() {
            if (this.transaction.total > 0) {
                this.$refs.refund.open()
                return;
            }

            this.toggleLinesRefund()
        },

        reload () {
            location.reload();
        },

        handleItemAdded () {
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
    },
};
</script>
